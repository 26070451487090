import React, { useState } from "react"
import styled from "styled-components"
import { ModalRoutingContext, Link } from "gatsby-plugin-modal-routing-3"
import InstaLogo from "../../../static/assets/icons/Instagram.svg"
import TwitterLogo from "../../../static/assets/icons/Twitter.svg"
import LinkedLogo from "../../../static/assets/icons/LinkedIn.svg"

const Button = styled.div`
  height: fit-content;
  width: fit-content;
  min-width: 140px;
  background: rgba(255, 255, 255, 1);
  border: 0.2px solid #000;
  border-radius: 20px;
  box-shadow: var(--cube-box-shadow);
  margin: 0.25em;
  padding: 8px 12px;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 0.7em;
  text-transform: uppercase;
  text-align: center;
  color: black;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: white;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.mini {
    padding: 6px 0px;
  }

  :hover {
    background: rgba(125, 125, 125, 0.5);
    transform: scale(1.1);
    transition: all 0.3s;
  }

  :active,
  focus {
    transform: scale(1);
    border-color: white;
    color: white;
  }

  &.linkedIn {
    width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    :active,
    focus {
      transform: scale(1.1);
      border-color: #000;
      filter: invert(100%);
      -webkit-filter: invert(100%);
    }
  }

  &.black {
    background: rgba(0, 0, 0, 1);
    border: 0.2px solid #000;
    color: white;

    :hover {
      background: rgba(50, 50, 50, 0.75);
    }

    :active,
    focus {
      transform: scale(1.1);
      background-color: white;
      border: 0.2px solid #fff;
      color: black;
    }
  }

  &.blue {
    background: var(--cube-grad-blue);
    border: none;
    color: white;

    :hover {
      background: var(--cube-color-blue);
    }

    :active,
    focus {
      transform: scale(1.1);
      background-color: white;
      color: black;
    }
  }

  &.right {
    align-self: flex-end;
    margin: 30px 30px 30px auto;

    @media (max-width: 768px) {
      margin: 30px auto;
    }
  }

  &.center {
    align-self: center;
    margin: 0 auto;
  }

  .hide {
    transform: scaleX(1);

    :active {
      transform: scaleX(0);
      transition: all 1s;
    }
  }

  .reveal {
    transform: scaleX(0);
    * {
      color: white;
    }

    :active {
      transform: scaleX(1);
      transition: all 1s;
    }
  }

  &.submit {
    :disabled {
      background: #aaa;
      border: 0.2px solid #aaa;
    }
  }

  @media (max-width: 768px) {
    margin: auto;
  }
`

const HoverRef = styled.a`
  text-align: center;
`

const OpenButton = styled(Link)`
  align-self: flex-end;
  transition: transform 300ms;

  :hover {
    transform: scale(1.3);
  }
`

export const PageButton = props => {
  return (
    <Link to={props.to} style={{ whiteSpace: "nowrap" }}>
      <Button className={`${props.class}`}>{props.children}</Button>
    </Link>
  )
}

export const ModalButton = props => {
  return (
    <ModalRoutingContext.Consumer>
      {({ closeTo }) => (
        <Link to={closeTo} style={{ whiteSpace: "nowrap" }}>
          <Button className={`${props.class}`}>{props.children}</Button>
        </Link>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const PopButton = props => {
  return (
    <Link
      to={props.to}
      style={{ whiteSpace: "nowrap" }}
      state={{ modal: true, noScroll: true }}
    >
      <Button className="black">{props.children}</Button>
    </Link>
  )
}

export const SubmitButton = props => {
  return (
    <Button
      as="button"
      type="submit"
      className="black submit"
      onClick={props.click}
      disabled={props.disabled}
    >
      <div>{props.children}</div>
    </Button>
  )
}

export const DetailsButton = props => {
  const [clicked, isClicked] = useState(false)

  const handleClick = email => {
    isClicked(!clicked)
    /*
    navigator.clipboard.writeText(email).then(
      function () {
        alert("Copied email to clipboard")
      },
      function (err) {
        console.error("Async: Could not copy text: ", err)
      }
    )
    */
  }

  return (
    <Button
      as="button"
      className={`${props.class}`}
      onClick={() => handleClick(props.children)}
    >
      {clicked ? (
        <div>{props.children}</div>
      ) : (
        <a href={`mailto:${props.children}`} target="_blank" rel="noreferrer">
          Email
        </a>
      )}
    </Button>
  )
}

//These following are the icons for social following section and menu.

export const HrefButton = props => {
  return (
    <a
      href={props.to}
      style={{ whiteSpace: "nowrap" }}
      target="_blank"
      rel="noreferrer"
    >
      <Button className={`${props.class}`}>{props.children}</Button>
    </a>
  )
}

export const DownloadButton = props => {
  return (
    <a href={props.href} style={{ whiteSpace: "nowrap" }} download>
      <Button className={`${props.class}`}>{props.children}</Button>
    </a>
  )
}

export const LinkedInIcon = () => {
  return (
    <HoverRef
      href="https://www.linkedin.com/company/cube-capital-ltd/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedLogo />
    </HoverRef>
  )
}

export const TwitterIcon = () => {
  return (
    <HoverRef
      href="https://twitter.com/CubeCapital_RC"
      target="_blank"
      rel="noreferrer"
    >
      <TwitterLogo />
    </HoverRef>
  )
}

export const InstagramIcon = () => {
  return (
    <HoverRef
      href="https://www.instagram.com/"
      target="_blank"
      rel="noreferrer"
    >
      <InstaLogo />
    </HoverRef>
  )
}

export const PlusButton = ({ slug }) => {
  return (
    <OpenButton asModal to={slug}>
      <div className="plus-button" />
    </OpenButton>
  )
}
